<script lang="ts" setup>
import { CfgSlider } from '@cfgtech/ui'
import { theme } from '~/theme'

type Props = {
  scoring: [number, number]
  min: number
  max: number
  step: number
}

const props = defineProps<Props>()

const emit = defineEmits<{
  'update:scoring': [[number, number]]
}>()

let lastChangedValue: 'of' | 'to' | null = null

const variants = [
  [props.min, 3.5],
  [4, 7],
  [7.5, 11],
  [11.5, 15],
  [15.5, props.max],
]

// Todo get from tailwind.config.js
const colors = [
  theme.colors.warning.default,
  theme.colors.warning.opacity,
  theme.colors.yellow.default,
  theme.colors.success.opacity,
  theme.colors.success.default,
]

const rootRef = ref<HTMLElement | null>(null)

function inRange(value: number, min: number, max: number): boolean {
  return value >= min && value <= max
}

const normalizedScoring = computed(() => {
  return [
    variants.findIndex(variant => inRange(props.scoring[0], variant[0], variant[1])),
    variants.findIndex(variant => inRange(props.scoring[1], variant[0], variant[1])),
  ]
})

const gradientCssVar = useCssVar('--gradient', rootRef)

watch(() => props.scoring, (newVal, prev) => {
  if (!newVal || isEqual(newVal, prev)) {
    return
  }

  gradientCssVar.value = `linear-gradient(90deg, ${generateGradient()})`

  setThumbColors()

  lastChangedValue = newVal[0] !== prev?.[0] ? 'of' : 'to'
}, { deep: true, immediate: true })

watch(rootRef, setThumbColors, { immediate: true })

/**
 * @param index - [0-4] - normalized index
 */
function valueApplyHandler(index: [number, number]) {
  let selectedRangeCopy: [number, number] = [...props.scoring]

  // get min and max values from variants by index
  const min = variants[index[0]][0]
  const max = variants[index[1]][1]

  if (isNumber(min) && isNumber(max)) {
    selectedRangeCopy = [min, max]
  }

  emit('update:scoring', [selectedRangeCopy[0], selectedRangeCopy[1]])
}

function generateGradient() {
  const [start, end] = getIndexes()

  const foundColors = colors.slice(start, end + 1)

  if (foundColors.length === 1) {
    return `${foundColors[0]} 0%, ${foundColors[0]} 100%`
  }

  if (foundColors.length === 2) {
    return `${foundColors[0]} 0%, ${foundColors[1]} 100%`
  }

  return colors
    .slice(start, end === colors.length - 1 ? end : end + 1)
    .map((color, index, array) => {
      return `${color} ${(index * 100 / (array.length - 1))}%`
    })
    .join(', ')
}

function setThumbColors() {
  const [leftHandlerColorValue, rightHandlerColorValue] = getColors()

  if (rootRef.value) {
    rootRef.value.style.setProperty('--left-handle-thumb-bg', leftHandlerColorValue)
    rootRef.value.style.setProperty('--right-handle-thumb-bg', rightHandlerColorValue)
  }
}

function getIndexes() {
  const start = variants.findIndex(([_, to]) => to >= props.scoring[0])
  const end = variants.findIndex(([_, to]) => to >= props.scoring[1])

  return [start, end]
}

function getColors() {
  const [start, end] = getIndexes()

  return [
    colors[start],
    colors[end],
  ]
}

function findIndexByVariantValue(valueNumber: number): number {
  return variants.findIndex((variant) => {
    return inRange(valueNumber, variant[0], variant[1])
  })
}

function onPipeClick(index: number): void {
  const [_, to] = variants[index]
  let localScoring

  if (lastChangedValue === 'of') {
    localScoring = [to, props.scoring[1]]
  }
  else {
    localScoring = [props.scoring[0], to]
  }

  valueApplyHandler([
    findIndexByVariantValue(localScoring[0]),
    findIndexByVariantValue(localScoring[1]),
  ])
}

function numberDotToComma(value: string | number): string {
  return String(value)?.replace('.', ',') || ''
}
</script>

<template>
  <div ref="rootRef" class="rainbow-slider">
    <CfgSlider
      :max="variants.length - 1"
      :min="0"
      :model-value="normalizedScoring"
      :step="1"
      :tooltips="false"
      @apply="valueApplyHandler($event as [number, number])"
    />

    <div class="ml-[-20px] mt-7 flex w-[calc(100%+40px)] justify-between">
      <button
        v-for="([of, to], index) in variants"
        :key="of"
        class="w-1/5 text-xs lg:transition-opacity lg:duration-200 lg:hover:opacity-70"
        type="button"
        @click="onPipeClick(index)"
      >
        {{ numberDotToComma(of) }} - {{ numberDotToComma(to) }}
      </button>
    </div>
  </div>
</template>

<style lang="scss">
.rainbow-slider {
  .slider-connects {
    position: relative;
  }

  .slider-connect {
    &:before {
      content: '';

      @apply absolute top-0 left-0 h-full w-full;
      background:  var(--gradient)
    }
  }
}
</style>
