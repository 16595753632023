<script lang="ts" setup>
import { TickIcon } from '@cfgtech/icons'
import { has } from 'lodash-es'
import {
  IssuesCollateralType,
} from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { filtersInitialState, issuesFiltersRanges } from '~/stores/issues/constants'
import { IssuesFiltersKeys, type IssuesListFilters } from '~/stores/issues/types'

type ExcludeKeys = IssuesFiltersKeys.OnlyOur

const props = defineProps<{
  appliedFilters: Partial<IssuesListFilters>
}>()

const emit = defineEmits<{
  'remove-filter': [Partial<IssuesListFilters>]
}>()

const { t } = useI18n()

const chips = computed(() => {
  const filters = props.appliedFilters

  if (isEmpty(filters)) {
    return []
  }

  type FilterChips = {
    [key in Exclude<IssuesFiltersKeys, ExcludeKeys>]: Array<{
      value: string
      index: number
      filterValue?: string
    }> | null
  }

  const foundCollateralTypes = Object.values(IssuesCollateralType).filter(type => filters[IssuesFiltersKeys.CollateralTypes]?.includes(type))

  const chips: FilterChips = {
    [IssuesFiltersKeys.AnnualInterestRate]: whenFilterExist(IssuesFiltersKeys.AnnualInterestRate, () => [
      whenIsNotDefaultValue(
        filters[IssuesFiltersKeys.AnnualInterestRate]![0],
        issuesFiltersRanges.annualInterestRate.min,
        t(`issuesListFilters.chips.${IssuesFiltersKeys.AnnualInterestRate}.from`, { from: filters[IssuesFiltersKeys.AnnualInterestRate]![0] }),
      ),

      whenIsNotDefaultValue(
        filters[IssuesFiltersKeys.AnnualInterestRate]![1],
        issuesFiltersRanges.annualInterestRate.max,
        t(`issuesListFilters.chips.${IssuesFiltersKeys.AnnualInterestRate}.to`, { to: filters[IssuesFiltersKeys.AnnualInterestRate]![1] }),
      ),
    ]),

    [IssuesFiltersKeys.DmatChecks]: whenFilterExist(IssuesFiltersKeys.DmatChecks, () => [
      whenIsNotDefaultValue(
        filters[IssuesFiltersKeys.DmatChecks],
        filtersInitialState[IssuesFiltersKeys.DmatChecks],
        t(`issuesListFilters.chips.${IssuesFiltersKeys.DmatChecks}.from`, { from: filters[IssuesFiltersKeys.DmatChecks] }),
      ),
    ]),

    [IssuesFiltersKeys.MfcrScore]: whenFilterExist(IssuesFiltersKeys.MfcrScore, () => [
      whenIsNotDefaultValue(
        filters[IssuesFiltersKeys.MfcrScore]![0],
        issuesFiltersRanges.mfcrScore.min,
        t(`issuesListFilters.chips.${IssuesFiltersKeys.MfcrScore}.from`, { from: filters[IssuesFiltersKeys.MfcrScore]![0] }),
      ),

      whenIsNotDefaultValue(
        filters[IssuesFiltersKeys.MfcrScore]![1],
        issuesFiltersRanges.mfcrScore.max,
        t(`issuesListFilters.chips.${IssuesFiltersKeys.MfcrScore}.to`, { to: filters[IssuesFiltersKeys.MfcrScore]![1] }),
      ),
    ]),

    [IssuesFiltersKeys.IssueMaturity]: whenFilterExist(IssuesFiltersKeys.IssueMaturity, () => [
      whenIsNotDefaultValue(
        filters[IssuesFiltersKeys.IssueMaturity]![0],
        issuesFiltersRanges.issueMaturity.min,
        t(`issuesListFilters.chips.${IssuesFiltersKeys.IssueMaturity}.from`, { from: filters[IssuesFiltersKeys.IssueMaturity]![0] }),
      ),

      whenIsNotDefaultValue(
        filters[IssuesFiltersKeys.IssueMaturity]![1],
        issuesFiltersRanges.issueMaturity.max,
        t(`issuesListFilters.chips.${IssuesFiltersKeys.IssueMaturity}.to`, { to: filters[IssuesFiltersKeys.IssueMaturity]![1] }),
      ),
    ]),

    [IssuesFiltersKeys.HasEarlyRepayment]: whenFilterExist(IssuesFiltersKeys.HasEarlyRepayment, () => [
      whenIsNotDefaultValue(
        filters[IssuesFiltersKeys.HasEarlyRepayment],
        filtersInitialState[IssuesFiltersKeys.HasEarlyRepayment],
        t(`issuesListFilters.chips.${IssuesFiltersKeys.HasEarlyRepayment}`),
      ),
    ]),

    [IssuesFiltersKeys.CanBeBoughtOnline]: whenFilterExist(IssuesFiltersKeys.CanBeBoughtOnline, () => [
      whenIsNotDefaultValue(
        filters[IssuesFiltersKeys.CanBeBoughtOnline],
        filtersInitialState[IssuesFiltersKeys.CanBeBoughtOnline],
        t(`issuesListFilters.chips.${IssuesFiltersKeys.CanBeBoughtOnline}`),
      ),
    ]),

    [IssuesFiltersKeys.CollateralTypes]: whenFilterExist(
      IssuesFiltersKeys.CollateralTypes,
      () => foundCollateralTypes.map((type) => {
        return t(`issuesListFilters.chips.${IssuesFiltersKeys.CollateralTypes}.${type}`)
      }),
      foundCollateralTypes,
    ),

    [IssuesFiltersKeys.IsProspectusApprovedByCNB]: whenFilterExist(IssuesFiltersKeys.IsProspectusApprovedByCNB, () => [
      whenIsNotDefaultValue(
        filters[IssuesFiltersKeys.IsProspectusApprovedByCNB],
        filtersInitialState[IssuesFiltersKeys.IsProspectusApprovedByCNB],
        t(`issuesListFilters.chips.${IssuesFiltersKeys.IsProspectusApprovedByCNB}`),
      ),
    ]),

    [IssuesFiltersKeys.StateBonds]: whenFilterExist(IssuesFiltersKeys.StateBonds, () => [
      whenIsNotDefaultValue(
        filters[IssuesFiltersKeys.StateBonds],
        filtersInitialState[IssuesFiltersKeys.StateBonds],
        t(`issuesListFilters.chips.${IssuesFiltersKeys.StateBonds}`),
      ),
    ]),
  }

  return Object.fromEntries(
    Object.entries(chips)
      .filter(([_, group]) => Boolean(group))
      .map(([key, group]) => [key, group!.filter(({ value }) => Boolean(value))]),
  )
})

const chipsAdded = computed(() => Object.values(chips.value).filter(group => group.length).length)

function whenFilterExist(key: IssuesFiltersKeys, getValue: () => string[], filterValue?: string[]) {
  return (has(props.appliedFilters, key)
    ? getValue().map((value, index) => ({ value, index, filterValue: filterValue?.[index] }))
    : null)
}

function whenIsNotDefaultValue<T>(value: T, compareWith: T, translate: string): string {
  return !isEqual(value, compareWith) ? translate : ''
}

function removeChip<T extends keyof IssuesListFilters>(key: T, index: number, filterValue?: string): void {
  const value = useCloneDeep(props.appliedFilters[key])

  // If filter is array and contains in issuesFiltersRanges
  if (Array.isArray(value)) {
    if (has(issuesFiltersRanges, key)) {
      value[index] = issuesFiltersRanges[key as keyof typeof issuesFiltersRanges]![index === 0 ? 'min' : 'max']
      return emit('remove-filter', {
        [key]: value,
      })
    }

    if (filterValue) {
      return (
        emit('remove-filter', {
          [key]: value!.filter(item => item !== filterValue),
        })
      )
    }
  }

  return emit('remove-filter', {
    [key]: filtersInitialState[key],
  })
}
</script>

<template>
  <ul v-if="chipsAdded" class="flex flex-wrap gap-2">
    <template v-for="(chipsGroup, key) in chips">
      <li
        v-for="{ value: chip, index, filterValue } in chipsGroup"
        :key="chip"
        class="chip group/chip bg-white"
      >
        <button
          class="border-grey-brand flex items-center gap-x-2 rounded-lg border border-brand bg-white px-3 py-2.5 text-xs leading-none"
          type="button"
          @click="removeChip(key as IssuesFiltersKeys, index, filterValue)"
        >
          {{ chip }}

          <TickIcon class="text-[0.8em] lg:transition lg:duration-200 lg:group-hover/chip:text-brand" />
        </button>
      </li>
    </template>
  </ul>
</template>
