<script lang="ts" setup>
/**
 * Filter component for the issues list.
 * After applying filter, emits 'changed' event with filter local copy.
 * On the desktop version, emits 'changed' event on every filter change.
 */
import { ResetIcon } from '@cfgtech/icons'
import { CfgButton, CfgSlideOver, CfgSlider, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { breakpointsTailwind } from '@vueuse/core'
import {
  IssuesCollateralType,
} from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { buildURLFilterPath } from '~/composables/filters/useFiltersUrls'
import { useIssuesListFilter } from '~/composables/issues/useIssuesList/useIssuesListFilter'
import { useIssuesSearch } from '~/composables/issues/useIssuesList/useIssuesSearch'
import { issuesFiltersRanges } from '~/stores/issues/constants'
import type { IssuesListFilters, RangeValue } from '~/stores/issues/types'
import { IssuesFiltersKeys } from '~/stores/issues/types'

type BooleanFilters = Pick<IssuesListFilters, IssuesFiltersKeys.HasEarlyRepayment | IssuesFiltersKeys.CanBeBoughtOnline | IssuesFiltersKeys.IsProspectusApprovedByCNB>
type RangeFilters = Pick<IssuesListFilters, IssuesFiltersKeys.AnnualInterestRate | IssuesFiltersKeys.IssueMaturity | IssuesFiltersKeys.MfcrScore>

const props = defineProps<{
  filter: Partial<IssuesListFilters>
  appliedFilters: Partial<IssuesListFilters>
}>()

const emit = defineEmits<{
  reset: []
  changed: [Partial<IssuesListFilters>]
}>()

const isOpened = defineModel('isOpened', {
  type: Boolean as PropType<boolean>,
  default: false,
})

const { t } = useI18n()

const { search } = useIssuesSearch()

// Used for local copy of filter to prevent changing filter in a parent component before apply on mobile
const {
  prefetchFilter,
  filteredResultsCount: localFilteredResultsCount,
  setPartialFilter,
  isFilterInInitialState,
  isPrefetchPending,
  filter: localFilter,
} = useIssuesListFilter('LOCAL_FILTER', search)

const { refresh: prefetchFilterWithUseAsyncData } = useAsyncData('prefetchedFilter', async () => {
  await prefetchFilter()
  return null
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const isLargeDesktop = computed(() => process.server ? true : breakpoints.greaterOrEqual('xl').value)

// Filters
const annualInterestRate = rangeFilterGetter(IssuesFiltersKeys.AnnualInterestRate)
const issueMaturity = rangeFilterGetter(IssuesFiltersKeys.IssueMaturity)
const mfcrScore = rangeFilterGetter(IssuesFiltersKeys.MfcrScore)

const hasEarlyRepayment = booleanFilterGetter(IssuesFiltersKeys.HasEarlyRepayment)
const canBeBoughtOnline = booleanFilterGetter(IssuesFiltersKeys.CanBeBoughtOnline)
const isProspectusApprovedByCNB = booleanFilterGetter(IssuesFiltersKeys.IsProspectusApprovedByCNB)

// COLLATERAL TYPES
// const debouncedBuildURLFilterPath = useDebounceFn((filters: Partial<IssuesListFilters>) => buildURLFilterPath(filters), 500);

const collateralTypesOptions = Object.values(IssuesCollateralType).filter(value => ![IssuesCollateralType.none].includes(value))
const collateralTypes = computed(() => localFilter.value.collateralTypes || [])

const filtersUrls = useState<{
  canBeBoughtOnline: string | undefined
  collaterals: Partial<Record<IssuesCollateralType, string>>
}>('filtersUrls', () => ({
  canBeBoughtOnline: '',
  collaterals: {},
}))

watch(() => props.appliedFilters, (appliedFilters) => {
  const updatedUrls = {
    canBeBoughtOnline: buildURLFilterPath({
      ...appliedFilters,
      canBeBoughtOnline: !canBeBoughtOnline.value,
    }),
    collaterals: Object.values(IssuesCollateralType).reduce((acc, collateralType) => {
      acc[collateralType] = buildURLFilterPath({
        ...appliedFilters,
        collateralTypes: (appliedFilters.collateralTypes || [])?.includes(collateralType)
          ? appliedFilters.collateralTypes?.filter(type => type !== collateralType)
          : [...appliedFilters.collateralTypes || [], collateralType],
      })
      return acc
    }, {} as Partial<Record<IssuesCollateralType, string>>),
  }

  filtersUrls.value = updatedUrls
}, { deep: true, immediate: true })

watchDebounced([localFilter, search], () => {
  prefetchFilterWithUseAsyncData()

  if (isLargeDesktop.value && !isEqual(props.filter, localFilter.value)) {
    emit('changed', localFilter.value)
  }
}, { deep: true, debounce: 350, maxWait: 5000 })

watch(() => props.filter, () => {
  if (!isEqual(props.filter, localFilter.value)) {
    setPartialFilter(useCloneDeep(props.filter))
  }
}, { immediate: true, deep: true })

watch(isOpened, (value) => {
  if (!value) {
    setPartialFilter(useCloneDeep(props.filter))
  }
})

function rangeDefaults(key: keyof typeof issuesFiltersRanges): RangeValue {
  return [issuesFiltersRanges[key].min, issuesFiltersRanges[key].max] as [number, number]
}

function rangeFilterGetter<Key extends keyof RangeFilters>(key: Key) {
  return computed({
    get: () => localFilter.value[key] || rangeDefaults(key),
    set: (value) => {
      handleFilterChanged(key, value)
    },
  })
}

function booleanFilterGetter(key: keyof BooleanFilters) {
  return computed({
    get: () => localFilter.value[key] || false,
    set: value => (handleFilterChanged(key, value)),
  })
}

function updateSelectedCollateralTypes(isSelected: boolean, type: IssuesCollateralType) {
  const selectedCollateralType = new Set<IssuesCollateralType>(collateralTypes.value)

  if (isSelected) {
    selectedCollateralType.add(type)
  }
  else {
    selectedCollateralType.delete(type)
  }

  handleFilterChanged(IssuesFiltersKeys.CollateralTypes, [...selectedCollateralType])
}

function handleFilterChanged<Key extends keyof IssuesListFilters>(key: Key, value: IssuesListFilters[Key]) {
  setPartialFilter({ [key]: value })
}

function resetFilter() {
  emit('reset')
  isOpened.value = false
}

function applyButtonClickHandler() {
  isOpened.value = false

  if (!isLargeDesktop.value) {
    emit('changed', localFilter.value)
  }
}

function removeFilter<T extends IssuesFiltersKeys>({ key, value }: { key: T, value: IssuesListFilters[T] }): void {
  handleFilterChanged(key, value)
}
</script>

<template>
  <!-- DIV COMP -->
  <Component
    :is="isLargeDesktop ? 'div' : CfgSlideOver"
    v-bind="(!isLargeDesktop ? {
      size: 'sm',
      closable: true,
      opened: isOpened,
    } : {})"
    v-on="!isLargeDesktop ? {
      'update:opened': (value: boolean) => isOpened = value,
    } : {}"
  >
    <div class="flex max-w-full flex-1 flex-col xl:mt-0">
      <div class="grow space-y-9 px-1 xl:mb-0 xl:px-0">
        <header>
          <div class="flex items-center justify-between">
            <CfgTypography :size="CfgTypographySizes.h3" tag-name="h2">
              {{ t('issuesListFilters.title') }}
            </CfgTypography>

            <CfgButton
              v-if="!isFilterInInitialState"
              class="rounded-md !py-1.5 px-2 text-xs leading-3"
              flat
              icon-placement="right"
              text
              @click="resetFilter"
            >
              {{ t('issuesListFilters.buttons.reset') }}
              <template #icon>
                <ResetIcon />
              </template>
            </CfgButton>
          </div>

          <CfgTypography class="mt-2 text-grey-500" :size="CfgTypographySizes.xs">
            {{ $t('issuesListFilters.count.text', { count: localFilteredResultsCount }) }}
          </CfgTypography>

          <LazyIssueFilterChips
            v-if="!isLargeDesktop"
            :applied-filters="appliedFilters"
            class="border-b border-grey-stroke py-6 xl:!hidden"
            @remove-filter="removeFilter"
          />
        </header>

        <div
          class="space-y-10"
          style="
            --slider-bg: rgba(var(--grey-300, var(--grey-300-default)));
          "
        >
          <div class="space-y-8">
            <!-- CAN BE BOUGHT ONLINE - HIDDEN FOR A/B TESTING -->
            <IssueFilterCheckboxWrapper
              id="ABcanBeBoughtOnline"
              v-model="canBeBoughtOnline"
              class="hidden"
              :label="$t('issuesListFilters.canBeBoughtOnline.label')"
              :link="filtersUrls.canBeBoughtOnline"
            />

            <!-- ANNUAL INTEREST RATE -->
            <IssueFilterOptionWrapper
              :hint="$t('issuesListFilters.annualInterestRate.hint')"
              :title="$t('issuesListFilters.annualInterestRate.title')"
            >
              <div class="mt-7 px-2">
                <CfgSlider
                  v-model="annualInterestRate"
                  :max="issuesFiltersRanges.annualInterestRate.max"
                  :min="issuesFiltersRanges.annualInterestRate.min"
                  :step="1"
                  tip-suffix="%"
                  use-inputs
                />
              </div>
            </IssueFilterOptionWrapper>
          </div>

          <!-- Collaterals -->
          <IssueFilterOptionWrapper
            class="flex flex-col [&>header]:grow [&>main]:!mt-0"
            :hint="$t('issuesListFilters.dmatChecks.hint')"
            :title="$t('issuesListFilters.dmatChecks.title')"
          >
            <div class="mt-3 space-y-2">
              <IssueFilterCheckboxWrapper
                v-for="(availableCollateralType, index) in collateralTypesOptions"
                :id="`collateral-${index}`"
                :key="availableCollateralType"
                :hint="$t(`issuesListFilters.collaterals.hint.${availableCollateralType}`)"
                :label="availableCollateralType"
                :link="filtersUrls.collaterals[availableCollateralType]"
                :model-value="collateralTypes.includes(availableCollateralType)"
                @update:model-value="updateSelectedCollateralTypes($event, availableCollateralType)"
              />
            </div>
          </IssueFilterOptionWrapper>

          <!-- MFCR SCORE -->
          <IssueFilterOptionWrapper
            data-ab-risk="a"
            :hint="$t('issuesListFilters.mfcrScore.hint')"
            :title="$t('issuesListFilters.mfcrScore.title')"
          >
            <div class="mb-5 mt-2 flex justify-between">
              <CfgTypography :size="CfgTypographySizes.xs">
                {{ $t('issuesListFilters.mfcrScore.slider.highRisk') }}
              </CfgTypography>

              <CfgTypography :size="CfgTypographySizes.xs">
                {{ $t('issuesListFilters.mfcrScore.slider.lowRisk') }}
              </CfgTypography>
            </div>

            <div class="px-2">
              <IssueFilterScoring
                v-model:scoring="mfcrScore"
                :max="issuesFiltersRanges.mfcrScore.max"
                :min="issuesFiltersRanges.mfcrScore.min"
                :step="3.5"
              />
            </div>
          </issuefilteroptionwrapper>

          <!-- ISSUE MATURITY -->
          <IssueFilterOptionWrapper
            :hint="$t('issuesListFilters.issueMaturity.hint')"
            :title="$t('issuesListFilters.issueMaturity.title')"
          >
            <div class="mt-7 px-2">
              <CfgSlider
                v-model="issueMaturity"
                :max="issuesFiltersRanges.issueMaturity.max"
                :min="issuesFiltersRanges.issueMaturity.min"
                :step="1"
                :tip-suffix="t('common.years')"
                use-inputs
              />
            </div>

            <IssueFilterCheckboxWrapper
              id="hasEarlyPayment"
              v-model="hasEarlyRepayment"
              class="mt-3 shrink-0"
              :hint="$t('issuesListFilters.hasEarlyRepayment.hint')"
              :label="$t('issuesListFilters.hasEarlyRepayment.label')"
            />
          </IssueFilterOptionWrapper>

          <!-- ADVANCED FILTERS -->
          <div>
            <div class="-ml-6 w-[calc(100%+3rem)] px-6">
              <CfgTypography
                class="mb-5 !font-highlighted"
                :size="CfgTypographySizes.md"
                tag-name="h3"
              >
                {{ $t('issuesListFilters.advanced.title') }}
              </CfgTypography>

              <div class="space-y-2">
                <IssueFilterCheckboxWrapper
                  id="canBeBoughtOnline"
                  v-model="canBeBoughtOnline"
                  data-cy="canBeBoughtOnline"
                  :label="$t('issuesListFilters.canBeBoughtOnline.label')"
                  :link="filtersUrls.canBeBoughtOnline"
                />

                <IssueFilterCheckboxWrapper
                  id="isProspectusApprovedByCNB"
                  v-model="isProspectusApprovedByCNB"
                  :hint="$t('issuesListFilters.isProspectusApprovedByCNB.hint')"
                  :label="$t('issuesListFilters.isProspectusApprovedByCNB.label')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Apply button -->
      <footer class="apply-sticky-button__wrapper sticky bottom-0 z-sticky flex translate-y-6 justify-center pb-24 pt-10 xl:hidden">
        <CfgButton
          :loading="isPrefetchPending"
          variant="primary"
          @click="applyButtonClickHandler"
        >
          {{ $t('issuesListFilters.showIssuesCount', { count: localFilteredResultsCount }) }}
        </CfgButton>
      </footer>
    </div>
  </Component>
</template>

<style lang="scss" scoped>
.apply-sticky-button {
  &__wrapper {
    background: linear-gradient(360deg, theme('colors.white') 18.13%, theme('colors.white/0') 150.83%);
  }
}

.cfg-slider {
  --slider-bg: theme('colors.grey.300');
}
</style>
