import { cloneDeep } from 'lodash-es'
import type { ComputedRef } from 'vue'
import type { IssuesListFilters } from './types'
import { IssuesFiltersKeys } from '~/stores/issues/types'
import type { MaybeDeepReadonly } from '~/types/helpers'
import { removeEqualValues } from '~/helpers/functions'
import { getIssues } from '~/composables/issues/useIssuesList/helpers'

export const filtersInitialState: Partial<IssuesListFilters> = {
  [IssuesFiltersKeys.AnnualInterestRate]: null,
  [IssuesFiltersKeys.MfcrScore]: null,
  [IssuesFiltersKeys.IssueMaturity]: null,
  [IssuesFiltersKeys.CollateralTypes]: [],
  [IssuesFiltersKeys.HasEarlyRepayment]: false,
  [IssuesFiltersKeys.IsProspectusApprovedByCNB]: false,
  [IssuesFiltersKeys.StateBonds]: false,
  [IssuesFiltersKeys.OnlyOur]: false,
  [IssuesFiltersKeys.CanBeBoughtOnline]: false,
  [IssuesFiltersKeys.DmatChecks]: 0,
  [IssuesFiltersKeys.BusinessCategory]: [],
}

export function useIssuesListFilter(key = 'ISSUES_LIST_FILTER', search?: ComputedRef<string>) {
  const filter = useState<Partial<IssuesListFilters>>(key, () => cloneDeep(filtersInitialState))
  const filteredResultsCount = useState<number>(`${key}_RESULTS_COUNT`, () => 0)

  const isFilterInInitialState = computed(() => isEqual(filter.value, filtersInitialState))

  const appliedFilters = computed(() => removeEqualValues(useCloneDeep(filter.value), filtersInitialState))
  const isFilterDefault = computed(() => Boolean(!Object.values(appliedFilters.value).length))
  const isPrefetchPending = useState<boolean>(`${key}_PREFETCH_PENDING`, () => false)

  function setPartialFilter(filterPartial: Partial<MaybeDeepReadonly<IssuesListFilters>>) {
    const resultFilter = {
      ...filter.value,
      ...filterPartial as Partial<IssuesListFilters>,
    }

    if (!isEqual(resultFilter, filter.value)) {
      filter.value = resultFilter
    }
  }

  async function prefetchFilter() {
    isPrefetchPending.value = true

    const prefetchedFilteredIssues = await getIssues({
      filters: {
        ...filter.value,
        ...(!search?.value ? { isNotArchived: true } : { }),
      },
      pagination: { start: 0, limit: 0 },
      search: search?.value || '',
    })

    filteredResultsCount.value = prefetchedFilteredIssues.meta?.pagination?.total || 0

    isPrefetchPending.value = false
  }

  return {
    filter: computed(() => filter.value),
    appliedFilters,
    isFilterDefault,
    filteredResultsCount: computedEager(() => filteredResultsCount.value),
    isFilterInInitialState: readonly(isFilterInInitialState),
    isPrefetchPending,
    setPartialFilter,
    prefetchFilter,
    reset() {
      setPartialFilter(cloneDeep(filtersInitialState))
    },
  }
}
