import { IssueSortTypes } from '~/composables/issues/useIssuesList/types'

export function useIssuesListSorting() {
  const sort = useState<IssueSortTypes>('HOMEPAGE_ISSUES_SORTING', () => IssueSortTypes.DEFAULT)

  function setSort(value: IssueSortTypes) {
    if (!Object.values(IssueSortTypes).includes(value)) {
      value = IssueSortTypes.DEFAULT
    }
    sort.value = value
  }

  return {
    sort: computed(() => sort.value),
    setSort,
  }
}
