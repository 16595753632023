import { useIssuesListSorting } from '../issues/useIssuesList/useIssuesListSorting'
import { useIssuesListFilter } from '../issues/useIssuesList/useIssuesListFilter'
import { useIssuesConfigurationSync } from '../issues/useIssuesList/useIssuesConfigurationSync'
import { useIssuesSearch } from '../issues/useIssuesList/useIssuesSearch'
import type { IssuesTypesState } from '../issues/useIssuesList/types'
import { useHomepageBlogPreview } from '../blog/useHomepageBlogPreview'
import type { SchemaOrgIssue } from '~/api/modules/issues/services/getSchemaOrgIssues/getSchemaOrgIssues.types'
import type { ApiResponseData, BusinessCategory, Homepage } from '~/api/types'
import { getSchemaOrgIssuesService } from '~/api/modules/issues/services/getSchemaOrgIssues/getSchemaOrgIssues.service'
import { api } from '~/api/Api'
import { useIssuesListBanner } from '~/composables/issues/useIssuesList/useIssuesListBanner'
import { paths } from '~/constants/paths'

export type BusinessCategoryWithId = BusinessCategory & { id: number }

export function useHomepage(fetchIssues: (ignoreCache?: boolean) => Promise<IssuesTypesState>) {
  const { fullPath, name } = useRoute()

  const { sort, setSort } = useIssuesListSorting()

  const { fetchPosts, posts } = useHomepageBlogPreview()

  const {
    filter,
    appliedFilters,
    setPartialFilter,
    prefetchFilter,
  } = useIssuesListFilter()

  const { search, setSearch } = useIssuesSearch()

  const { validateURL, parseUrlToConfiguration } = useIssuesConfigurationSync({
    filter,
    sorting: sort,
    search,
    appliedFilters,
    fullPath,
  })

  const savedPageData = useState<{
    businessCategories: BusinessCategoryWithId[]
    pageData: Homepage | null
    schemaOrgIssues: ApiResponseData<SchemaOrgIssue[]>
  } | null>('issue', () => null)

  const prevRouteName = useState<string | null>('PREV_ROUTE', () => null)

  async function resolveHomepage() {
    const issuesPath = /^\/dluhopisy\/firemni|statni\/?$/

    if (!validateURL(fullPath) && !issuesPath.test(fullPath.split('?')[0].split('#')[0])) {
      return showError({ statusCode: 404 })
    }

    const parsedConfiguration = parseUrlToConfiguration(fullPath)
    const prevFilter = useCloneDeep(filter.value)
    const prevSort = sort.value
    const prevSearch = search.value

    setPartialFilter({
      ...parsedConfiguration.filter,
      stateBonds: fullPath.startsWith(paths.stateIssues),
    })

    setSort(parsedConfiguration.sorting)
    setSearch(parsedConfiguration.search)

    // Get cached data and return it if the route name is the same
    if (prevRouteName.value === name) {
      if (
        !isEqual(prevFilter, filter.value)
        || prevSort !== sort.value
        || prevSearch !== search.value
      ) {
        try {
          await Promise.all([
            fetchIssues(true),
            prefetchFilter(),
          ])
        }
        catch (_) {
          showError({ statusCode: 404 })
        }
      }

      return savedPageData.value // Return cached data to avoid redundant requests
    }

    // Fetch data if the route name is different
    try {
      const [pageData, schemaOrgIssues, businessCategories] = await Promise.all([
        api.pages.homepage.getHomepage(),
        getSchemaOrgIssuesService(),
        api.bussinessCategoriesModule.getBusinessCategoriesList(),
        fetchIssues(),
        prefetchFilter(),
        useIssuesListBanner().fetchBanner(),
        ...(!posts.value ? [fetchPosts()] : []), // Fetch blog posts if they are not fetched yet
      ])

      prevRouteName.value = name as string

      const normalizedBusinessCategories = businessCategories.data.map(({ attributes, id }) => ({ ...attributes, id }))

      // Save data to cache
      savedPageData.value = {
        pageData,
        schemaOrgIssues,
        businessCategories: normalizedBusinessCategories,
      }

      return {
        pageData,
        schemaOrgIssues,
        businessCategories: normalizedBusinessCategories,
      }
    }
    catch (_) {
      showError({ statusCode: 404 })
    }
  }

  return {
    resolveHomepage,
    savedPageData: computed(() => savedPageData.value),
  }
}
