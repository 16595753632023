<script lang="ts" setup>
import { type CfgBreadcrumbsPage, CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { IssueListView } from '#components'
import type { MetaSocial } from '~/api/types/generated/components/MetaSocial'
import { useHomepageBlogPreview } from '~/composables/blog/useHomepageBlogPreview'
import { useFilteredListsMeta } from '~/composables/filters'
import { useHomepage } from '~/composables/homepage/useHomepage'
import { useIssuesLists } from '~/composables/issues'
import { useIssuesConfigurationSync } from '~/composables/issues/useIssuesList/useIssuesConfigurationSync'
import { useIssuesListBanner } from '~/composables/issues/useIssuesList/useIssuesListBanner'
import { useIssuesListFilter } from '~/composables/issues/useIssuesList/useIssuesListFilter'
import { useIssuesListSorting } from '~/composables/issues/useIssuesList/useIssuesListSorting'
import { useIssuesSearch } from '~/composables/issues/useIssuesList/useIssuesSearch'
import { paths } from '~/constants/paths'

definePageMeta({
  path: `${paths.issues}/:category(statni|firemni)/::filters(.*)*`,
})

const { t } = useI18n()

const route = useRoute()

// State issues
if (route.params.filters.includes('statni-dluhopisy')) {
  navigateTo(paths.stateIssues, { redirectCode: 301 })
}

const isStateIssuesCategory = computed(() => route.params.category === 'statni')

// Sort
const { sort, setSort } = useIssuesListSorting()
const { search, setSearch } = useIssuesSearch()

// Filter
const {
  filter,
  appliedFilters,
  isFilterDefault,
  reset: resetFilter,
  setPartialFilter,
  prefetchFilter,
} = useIssuesListFilter(undefined, search)

const { filteredListsMeta } = useFilteredListsMeta(filter, t)

// List
const { issues, fetchInitialFiltersList, fetchMoreIssues } = useIssuesLists(
  filter,
  sort,
  search,
)

const { posts } = useHomepageBlogPreview()

const { resolveHomepage, savedPageData } = useHomepage(fetchInitialFiltersList)

// URL sync
const { configurationToUrl } = useIssuesConfigurationSync({
  filter,
  sorting: sort,
  search,
  appliedFilters,
  fullPath: route.fullPath,
})

const { banner } = useIssuesListBanner()

const issuesListView = ref<InstanceType<typeof IssueListView> | null>(null)

await useAsyncData('issues', resolveHomepage, { pick: [] })

watch(sort, () => {
  redirect(configurationToUrl())
  fetchInitialFiltersList(true)
})

watchDebounced(
  [() => filter.value, search],
  ([filter, search], [prevFilter, prevSearch]) => {
    if (!isEqual(filter, prevFilter) || search !== prevSearch) {
      redirect(configurationToUrl())
      fetchInitialFiltersList(true)
      prefetchFilter()
    }
  },
  { deep: true, debounce: 350 },
)

watch(
  [savedPageData, () => filteredListsMeta.value.seo],
  () => {
    const seo = savedPageData.value?.pageData?.seo

    useSeo({
      id: 0,
      metaTitle: isFilterDefault.value
        ? seo?.metaTitle || t('common.seo.title')
        : filteredListsMeta.value.seo.title,
      metaDescription: isFilterDefault.value
        ? seo?.metaDescription || t('common.seo.description')
        : filteredListsMeta.value.seo.description,
      keywords: `${seo?.keywords ? `${seo?.keywords},` : ''}${
        filteredListsMeta.value.seo.keywords
      }`,
      metaSocial:
        seo?.metaSocial?.map?.(
          (social): MetaSocial =>
            ({
              socialNetwork: social.socialNetwork,
              title: social.title || seo.metaTitle || t('common.seo.title'),
              description:
                social.description
                || seo.metaDescription
                || t('common.seo.description'),
              image: social.image,
              imageAlternativeText:
                social.imageAlternativeText || t('common.seo.imageAltHomePage'),
            }) as MetaSocial,
        ) || [],
    })
  },
  { deep: true, immediate: true },
)

function redirect(url: string) {
  if (route.fullPath === decodeURI(url)) {
    return
  }

  return navigateTo(url, { redirectCode: 301 })
}

const breadcrumbPages = computed<CfgBreadcrumbsPage[]>(() => [
  {
    name: isStateIssuesCategory.value ? t('nav.stateIssues') : t('nav.home'),
    path: isStateIssuesCategory.value ? paths.stateIssues : paths.corporateIssues,
    current: false,
  },
])
</script>

<template>
  <div>
    <GTMProvider v-slot="{ events, locations }">
      <div class="flex-1 [&>*]:w-full">
        <!-- BREADCRUMB -->
        <section class="container my-6">
          <BaseBreadcrumb :pages="breadcrumbPages" />
        </section>

        <section class="container">
          <IssueListView
            ref="issuesListView"
            :applied-filters="appliedFilters"
            :banner="banner"
            :business-categories="savedPageData?.businessCategories || []"
            :filter="filter"
            :hide-header="isStateIssuesCategory"
            :issues="issues"
            :lists-description="!isFilterDefault ? filteredListsMeta.motto : null"
            :search="search"
            :sort="sort"
            :title="isStateIssuesCategory ? $t('nav.stateIssues') : (!isFilterDefault ? filteredListsMeta.listTitle : null)"
            @filter:reset="resetFilter"
            @load-more="fetchMoreIssues"
            @update:filter="setPartialFilter"
            @update:search="setSearch"
            @update:sort="setSort"
          >
            <template v-if="isStateIssuesCategory" #sidebar>
              <div class="space-y-4 rounded-xl p-6 shadow-[0px_0px_6px_0px_hsla(0,0%,0%,0.25)]">
                <CfgTypography :size="CfgTypographySizes.h3" tag-name="h3">
                  Víte, že firemní dluhopisy
                </CfgTypography>

                <CfgTypography :size="CfgTypographySizes.md" tag-name="p">
                  mají výnos až 12 % ročně a je možné je zakoupit online u emitenta?
                </CfgTypography>

                <NuxtLink class="block" :to="paths.corporateIssues">
                  <CfgButton class="w-full py-2" small>
                    Zjistit více
                  </CfgButton>
                </NuxtLink>
              </div>
            </template>

            <template v-if="isStateIssuesCategory" #description>
              <CfgTypography :size="CfgTypographySizes.md">
                Dluhopisy vydávané Ministerstvem financí ČR. Jedná se o nejbezpečnější investice, ale s <strong>nejmenším průměrným výnosem</strong>.
              </CfgTypography>
            </template>

            <template v-if="isStateIssuesCategory" #footerDescription>
              <CfgTypography
                class="mb-4 mt-6"
                :size="CfgTypographySizes.h2"
                tag-name="h2"
              >
                Porovnání státních a firemních dluhopisů: Které jsou pro vás vhodné?
              </CfgTypography>

              <CfgTypography
                class="mb-10"
                :size="CfgTypographySizes.md"
                tag-name="p"
              >
                Rozhodnutí o investici do dluhopisů může být složité. Podívejme se na rozdíly mezi státními a firemními dluhopisy, abyste mohli lépe porozumět jejich vlastnostem a rozhodnout se, které jsou pro vás vhodné.
              </CfgTypography>

              <div class="mb-10 grid grid-cols-1 gap-x-20 gap-y-10 xl:grid-cols-2">
                <div>
                  <CfgTypography
                    class="mb-2"
                    :size="CfgTypographySizes.h3"
                    tag-name="h3"
                  >
                    Výhody státních dluhopisů
                  </CfgTypography>

                  <CfgTypography :size="CfgTypographySizes.md" tag-name="p">
                    <span class="mb-4 block">Státní dluhopisy jsou obecně považovány za bezpečnou investici s nízkým rizikem selhání, a proto jsou atraktivní pro konzervativní investory. Mezi hlavní výhody státních dluhopisů patří:</span>

                    <ul class="ml-4 list-inside list-disc">
                      <li>Nízké riziko: Státní dluhopisy jsou obvykle považovány za méně rizikové než firemní dluhopisy, protože jsou podloženy zárukou státu.</li>

                      <li>Stabilita: Investice do státních dluhopisů poskytuje stabilní a spolehlivé příjmy v podobě úroků.</li>
                    </ul>
                  </CfgTypography>
                </div>

                <div>
                  <CfgTypography
                    class="mb-2"
                    :size="CfgTypographySizes.h3"
                    tag-name="h3"
                  >
                    Výhody firemních dluhopisů
                  </CfgTypography>

                  <CfgTypography :size="CfgTypographySizes.md" tag-name="p">
                    <span class="mb-4 block">Firemní dluhopisy mohou nabídnout vyšší výnosnost a různorodost investičních možností. Mezi klíčové výhody firemních dluhopisů patří:</span>

                    <ul class="ml-4 list-inside list-disc">
                      <li>Vyšší výnosnost: Firemní dluhopisy v naší databázi dosahují vyšší úrokové sazby než státní dluhopisy, což může vést k atraktivnějším výnosům.</li>

                      <li>Možnost diverzifikace: Firemní dluhopisy umožňují investorům diverzifikovat své portfolio a snížit tak riziko spojené s jednotlivými investicemi.</li>
                    </ul>
                  </CfgTypography>
                </div>
              </div>

              <div class="flex justify-center">
                <NuxtLink :to="paths.corporateIssues">
                  <CfgButton>Přehled firemních dluhopisů</CfgButton>
                </NuxtLink>
              </div>
            </template>
          </IssueListView>
        </section>

        <section v-if="posts" class="mb-10 mt-16 bg-grey-50/30 py-10 lg:mb-16 lg:py-16">
          <BlogPreview
            :posts="posts"
            @show-more-articles="events.onBlogShowMoreClick(locations.HomepageBlogBottom)"
          />
        </section>
      </div>
    </GTMProvider>
  </div>
</template>
