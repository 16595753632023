import { getBanner } from '~/api/modules/issues/services/getIssuerIssues/getIssuerIssues.service'
import type { IssueCommercialBanner } from '~/api/types/generated/components/IssueCommercialBanner'

export function useIssuesListBanner() {
  const banner = useState<IssueCommercialBanner | null>('HOMEPAGE_ISSUES_BANNER', () => null)

  async function fetchBanner() {
    banner.value = await getBanner()
  }

  return {
    banner,
    fetchBanner,
  }
}
