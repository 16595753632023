import { BASE_FILTERS_URL } from '~/composables/filters/constants'
import { detectFilterType, parseFilterValue } from '~/composables/filters/helpers/parseUrlToFilters'

export function validateUrlByFilters(url: string) {
  const [issuesKey, issuesGroupKey, ...tokens] = url.split('?')[0].split('#')[0].split('/').filter(Boolean)

  const clearBaseFiltersUrl = BASE_FILTERS_URL.replace(/\/+/g, '')

  // Check if all filters are valid
  return issuesKey + issuesGroupKey === clearBaseFiltersUrl
    ? tokens.map((type, index) => {
      const detectedType = detectFilterType(type)
      return detectedType ? parseFilterValue(detectedType!, tokens[index]) : null
    }).every(filter => filter !== null)
    : false
}
