<script lang="ts" setup>
import { CfgButton, CfgFilterChip, CfgHint, CfgPreloader, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { CategoriesIcon, FilterIcon, QuestionCircleIcon } from '@cfgtech/icons'
import type { PropType, Ref } from 'vue'
import type { PaginationByOffset } from '@nuxtjs/strapi'
import { type IssueBuyEmit, IssueBuyUrlTypeEnum } from '../types'
import { HEADER_HEIGHT_DESKTOP } from '~/constants/common'
import { IssuesFiltersKeys, type IssuesListFilters, IssuesTypes } from '~/stores/issues/types'
import { IssueSortTypes, type IssueTypeState } from '~/composables/issues/useIssuesList/types'
import { useHandleBuyIssueModalCloseSendEvent } from '~/composables/issues/useHandleBuyIssueModalCloseSendEvent'
import type { IssueCommercialBanner } from '~/api/types/generated/components/IssueCommercialBanner'
import { BANNER_POSITION } from '~/stores/issues/constants'
import type { BusinessCategoryWithId } from '~/composables/homepage/useHomepage'
import { UNKNOWN_BUSINESS_CATEGORY_ID } from '~/constants/issue'

const props = withDefaults(defineProps<{
  appliedFilters: Partial<IssuesListFilters>
  issues: Record<IssuesTypes, Ref<IssueTypeState>>
  title: string | null
  banner?: IssueCommercialBanner | null
  listsDescription: string | null
  businessCategories: BusinessCategoryWithId[]
  hideHeader: boolean
}>(), {
  banner: null,
  hideHeader: false,
})

const emit = defineEmits<{
  'filter:reset': []
  'load-more': [IssuesTypes]
}>()

const search = defineModel<string>('search', {
  type: String as PropType<string>,
  default: '',
})

const filter = defineModel<Partial<IssuesListFilters>>('filter', {
  type: Object as PropType<Partial<IssuesListFilters>>,
  required: true,
})

const sort = defineModel<IssueSortTypes>('sort', {
  type: String as PropType<IssueSortTypes>,
  required: true,
})

const { t } = useI18n()

const statefullBusinessCategories = computed(() => {
  return [
    ...props.businessCategories,
    {
      id: UNKNOWN_BUSINESS_CATEGORY_ID,
      name: t('issuesListFilters.businessCategories.unknown.name'),
      icon: null,
    },
  ].map(category => ({
    ...category,
    isSelected: props.appliedFilters[IssuesFiltersKeys.BusinessCategory]?.includes(category.id),
  }))
})

const {
  issueToBuy,
  orderUrl,
  isIssueBuyModalOpen,
  issueBuyModalType,
  openIssueBuyModal,
  closeIssueBuyModal,
} = useIssueBuy()

const { onSrovAffiliateRedirectModalClick, onSrovOrderOnlineModalClick, onSrovAffiliateRedirectClick, onBuyNowClickEvent } = useDataLayer()
const listsRef = ref<HTMLElement | null>(null)
const isFilterOpened = useState('isFilterOpened', () => false)
const issueSortKeysMapper = {
  [IssueSortTypes.COLLATERALS]: 'dmatChecks',
  [IssueSortTypes.ANNUAL_INTEREST_RATE]: 'annualInterestRate',
  [IssueSortTypes.MFCR_SCORE]: 'mfcrScore',
}

const renderedLists = computed(() => {
  const list = []

  if (sort.value === IssueSortTypes.DEFAULT) {
    list.push({
      type: IssuesTypes.issues,
      sort: IssueSortTypes.DEFAULT,
      isLoading: props.issues.onlyOurIssues.value.isLoading || props.issues.issues.value.isLoading,
      list: [
        ...props.issues.onlyOurIssues.value.list,
        ...props.issues.issues.value.list,
      ],

      leftToLoad: calculateLeftToLoad(props.issues.issues.value.pagination, props.issues.issues.value.total),
      isSortAlertShown: false,
    })
  }
  else {
    const listType = issueSortKeysMapper[sort.value]

    list.push({
      sort: sort.value,
      type: IssuesTypes.allIssues,
      title: t(`bondList.lists.${listType}.title`),
      description: t(`bondList.lists.${listType}.description`),
      isLoading: props.issues.allIssues.value.isLoading,
      list: props.issues.allIssues.value.list,
      leftToLoad: calculateLeftToLoad(props.issues.allIssues.value.pagination, props.issues.allIssues.value.total),
      isSortAlertShown: false,
    })
  }

  if (search.value?.trim() && props.issues.archivedIssues.value.list.length) {
    list.push({
      sort: sort.value,
      type: IssuesTypes.archivedIssues,
      title: t('bondList.lists.archivedIssues.title'),
      description: t('bondList.lists.archivedIssues.description'),
      list: props.issues.archivedIssues.value.list,
      isLoading: props.issues.archivedIssues.value.isLoading,
      leftToLoad: calculateLeftToLoad(props.issues.archivedIssues.value.pagination, props.issues.archivedIssues.value.total),
      isSortAlertShown: false,
    })
  }

  if (props.banner) {
    list[0].list.splice(BANNER_POSITION, 0, {
      id: 'banner',
      banner: props.banner || undefined,
      loading: false,
    })
  }

  list[list.length - 1].isSortAlertShown = true

  return list
})

defineExpose({
  focus: handleListViewFocus,
})

function handleListViewFocus() {
  if (listsRef.value) {
    listsRef.value.scrollIntoView({ behavior: 'smooth' })
  }
}

function calculateLeftToLoad(pagination: PaginationByOffset, total: number) {
  return total - (pagination.offset + pagination.limit)
}

function handleIssueBuyModalOpen(data: IssueBuyEmit) {
  if (!data.url.orderUrl || !data.url.orderUrl || !data.issue) {
    return
  }

  openIssueBuyModal(data)

  switch (data.url.urlType) {
    case IssueBuyUrlTypeEnum.AFFILIATE:
      onSrovAffiliateRedirectModalClick(data.issue, DataLayerLocationsEnum.IssuesList)
      break

    case IssueBuyUrlTypeEnum.JAMES:
      onSrovOrderOnlineModalClick(data.issue, DataLayerLocationsEnum.IssuesList)
      break

    default:
      break
  }
}

function handleBuyNowEventClick() {
  if (!issueToBuy.value) {
    return
  }

  closeIssueBuyModal()

  switch (issueBuyModalType.value) {
    case IssueBuyUrlTypeEnum.AFFILIATE:
      onSrovAffiliateRedirectClick(issueToBuy.value, DataLayerLocationsEnum.IssuesList)
      break

    case IssueBuyUrlTypeEnum.JAMES:
      onBuyNowClickEvent(issueToBuy.value, DataLayerLocationsEnum.IssuesList)
      break

    default:
      break
  }
}

function getIssueSortEventType(key?: IssueSortTypes) {
  switch (key) {
    case IssueSortTypes.COLLATERALS:
      return 'secured'

    case IssueSortTypes.ANNUAL_INTEREST_RATE:
      return 'rate'

    case IssueSortTypes.MFCR_SCORE:
      return 'score'

    default:
      return 'best'
  }
}

function getIssueDetailClickEventType(issueType: IssuesTypes) {
  if (issueType === IssuesTypes.onlyOurIssues) {
    return 'best'
  }

  if (issueType === IssuesTypes.issues) {
    return 'more'
  }

  if (issueType === IssuesTypes.allIssues) {
    return getIssueSortEventType(sort.value)
  }

  return 'best'
}

function handleBuyIssueModalClose(closeType: 'backdrop' | 'button' | 'custom') {
  if (!issueToBuy.value || !issueBuyModalType.value) {
    return
  }

  useHandleBuyIssueModalCloseSendEvent().handleBuyIssueModalCloseSendEvent(
    closeType,
    issueToBuy.value,
    issueBuyModalType.value,
    DataLayerLocationsEnum.IssueDetails,
  )

  closeIssueBuyModal()
}

function handleLoadMoreIssues(type: IssuesTypes) {
  emit('load-more', type)
}

function handleFilterChange(value: Partial<IssuesListFilters>) {
  filter.value = value
}

function handleBussinesCategorySelect(id: number): 'remove' | 'add' {
  const isAlreadySelected = filter.value[IssuesFiltersKeys.BusinessCategory]?.includes(id)
  const otherSelectedCategories = filter.value[IssuesFiltersKeys.BusinessCategory]?.filter(
    categoryId => categoryId !== id,
  )

  handleFilterChange({
    [IssuesFiltersKeys.BusinessCategory]: isAlreadySelected
      ? otherSelectedCategories
      : [
          ...otherSelectedCategories || [],
          id,
        ],
  })

  return isAlreadySelected ? 'remove' : 'add'
}

const isDefaultSort = computed(() => {
  return sort.value === IssueSortTypes.DEFAULT
})
</script>

<template>
  <GTMProvider v-slot="{ events, eventsList, locations, trackEvent }">
    <div class="mb-16 flex flex-1 flex-col !p-0 lg:mb-24 lg:grid xl:grid-cols-7 xl:gap-x-8">
      <!-- FILTER -->
      <aside class="relative hidden xl:col-span-2 xl:flex">
        <BaseOverflowFloating class="max-w-full" :offset-y="HEADER_HEIGHT_DESKTOP">
          <slot name="sidebar">
            <div class="max-w-full rounded-xl bg-background-gray p-7">
              <IssueFilter
                v-model:is-opened="isFilterOpened"
                :applied-filters="appliedFilters"
                :filter="filter"
                @changed="(event: Partial<IssuesListFilters>) => {
                  handleFilterChange(event);
                  events.onFilterClickEvent('side');
                }"
                @reset="$emit('filter:reset')"
              />
            </div>
          </slot>
        </BaseOverflowFloating>
      </aside>

      <!-- LISTS WRAPPER -->
      <div
        id="issues-list"
        ref="listsRef"
        class="flex flex-1 flex-col gap-y-4 xl:col-span-5"
      >
        <CfgTypography :size="CfgTypographySizes.h2" tag-name="h2">
          {{ title || $t('homepage.title') }}
        </CfgTypography>

        <slot name="description" />

        <template v-if="!props.hideHeader">
          <div class="space-y-6">
            <div class="flex items-center gap-x-3">
              <!-- SEARCH -->
              <div class="grow">
                <FormSearchInput
                  id="homepage-search"
                  v-model="search"
                  class="shadow-light lg:!shadow-none"
                  :placeholder="$t('bondList.search.placeholder')"
                  @clear="search = ''"
                />
              </div>

              <!-- MOBILE FILTER OPEN -->
              <div class="flex h-full xl:hidden">
                <CfgButton
                  class="flex flex-1 flex-col items-center justify-center gap-[0.1em]"
                  small
                  style="--padding-y: 0.6rem; --padding-x: 1.3rem;"
                  type="submit"
                  @click="isFilterOpened = !isFilterOpened"
                >
                  <ClientOnly>
                    <FilterIcon class="text-lg" />
                  </ClientOnly>

                  <span class="mt-1 block text-[12px] font-normal">
                    {{ $t('issuesListFilters.trigger.label') }}
                  </span>
                </CfgButton>
              </div>
            </div>

            <!-- BUSINESS TYPES FILTER. TODO: Implement mobile version -->
            <ul class="hidden flex-wrap gap-3 pb-3 lg:flex">
              <li v-for="{ id, isSelected, name, icon } of statefullBusinessCategories" :key="id">
                <CfgFilterChip
                  :label="name"
                  :model-value="isSelected"
                  @update:model-value="() => {
                    if (handleBussinesCategorySelect(id) === 'add') {
                      trackEvent(eventsList.SrovBusinessCategorySelect, {
                        location: locations.IssuesList,
                        category: name,
                      })
                    }
                  }"
                >
                  <template #leadingIcon>
                    <NuxtImg
                      v-if="icon"
                      alt=""
                      aria-hidden="true"
                      height="20"
                      loading="lazy"
                      :src="icon.data.attributes.url"
                      width="20"
                    />

                    <CategoriesIcon v-else class="text-[20px] text-brand" />
                  </template>
                </CfgFilterChip>
              </li>
            </ul>
          </div>

          <!-- CHIPS -->
          <ClientOnly>
            <IssueFilterChips
              :applied-filters="appliedFilters"
              @remove-filter="filter = $event"
            />
          </ClientOnly>

          <!-- SORT -->
          <IssueSorting
            class="pt-2"
            :sort="sort"
            @update:sort="(event: any) => {
              sort = event;
              events.onSortClick(getIssueSortEventType(event))
            }"
          />
        </template>

        <!-- LISTS -->
        <div>
          <div
            v-for="{ title: listTitle, list, type, description, isLoading: isListLoading, leftToLoad, isSortAlertShown } in renderedLists"
            :key="type"
            class="mt-3 flex flex-col gap-y-1 pb-10 last-of-type:pb-0"
          >
            <!-- LIST: TITLE -->
            <CfgTypography
              v-if="listTitle"
              class="flex items-center gap-x-3 pb-4 lg:pb-2"
              :size="CfgTypographySizes.h3"
              tag-name="h2"
            >
              {{ listTitle }}

              <CfgHint
                v-if="description"
                :disabled="!Boolean(description)"
                hide-icon
                interactive
              >
                <template #trigger>
                  <QuestionCircleIcon v-if="Boolean(description)" class="text-grey-200" />
                </template>

                <template #content>
                  <CfgTypography
                    class="prose"
                    :html="description"
                    :size="CfgTypographySizes.sm"
                    tag-name="p"
                  />
                </template>
              </CfgHint>
            </CfgTypography>

            <!-- LIST:HEAD -->

            <div v-if="Boolean(list.length)" class="sticky top-0 z-sticky hidden bg-white py-2 lg:block">
              <IssueTableHead />
            </div>

            <!-- LIST:ITEMS -->

            <template v-if="!isListLoading">
              <div
                v-if="
                  list.length
                    && !(list.length === 1 && list[0]?.id === 'banner') /* check if the list doesn't contain only banner */
                "
                class="relative z-0 flex w-full flex-col items-center space-y-8"
              >
                <ul class="w-full space-y-3">
                  <IssueList
                    :can-be-highlighted="type === IssuesTypes.onlyOurIssues"
                    :issues="list"
                    @buy:issue="handleIssueBuyModalOpen"
                    @click:issue-detail="events.onIssueClick($event, locations.Homepage, getIssueDetailClickEventType(type))"
                  />
                </ul>

                <!-- LIST:PAGINATION -->
                <CfgButton
                  v-if="leftToLoad > 0"
                  flat
                  :label="$t('bondList.showAll.label', leftToLoad >= 10 ? 10 : leftToLoad)"
                  small
                  text
                  variant="primary"
                  @click="handleLoadMoreIssues(type)"
                />

                <BaseAlert v-if="isDefaultSort && isSortAlertShown && !props.hideHeader" variant="info">
                  <CfgTypography :html="$t('homepage.sortAlert')" :size="CfgTypographySizes.md" />
                </BaseAlert>
              </div>

              <!-- LIST:EMPTY -->

              <LazyClientOnly v-else>
                <div class="w-full">
                  <LazyBaseEmptyView :description="$t(`bondList.notFound.title.${type}`)">
                    <template #button>
                      <button
                        class="cursor-pointer text-brand xl:hidden"
                        type="button"
                        @click="isFilterOpened = true"
                      >
                        {{ $t('bondList.notFound.reset') }}
                      </button>

                      <span class="hidden xl:inline">{{ $t('bondList.notFound.reset') }}</span>
                    </template>
                  </LazyBaseEmptyView>
                </div>
              </LazyClientOnly>
            </template>

            <!-- LIST:PENDING -->

            <ClientOnly v-else>
              <div class="flex flex-1 justify-center py-[15%] text-brand lg:min-h-[1600px]">
                <CfgPreloader />
              </div>
            </ClientOnly>
          </div>

          <slot name="footerDescription">
            <CfgTypography
              v-if="listsDescription"
              :html="listsDescription"
              tag-name="div"
            />
          </slot>
        </div>
      </div>
    </div>
  </GTMProvider>

  <ClientOnly>
    <LazyModalOrder
      :company-name="issueToBuy?.company?.name"
      :is-opened="isIssueBuyModalOpen"
      :issue-name="issueToBuy?.name"
      :order-url="orderUrl"
      :type="issueBuyModalType"
      @click-close:backdrop="handleBuyIssueModalClose('backdrop')"
      @click-close:button="handleBuyIssueModalClose('button')"
      @click-close:custom="handleBuyIssueModalClose('custom')"
      @open:order-form="handleBuyNowEventClick"
    />
  </ClientOnly>
</template>
