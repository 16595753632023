import type { ComputedRef, Ref } from 'vue'
import { cloneDeep, omit, pick } from 'lodash-es'
import type { IssuesListFilters } from '~/stores/issues/types'
import { filtersInitialState } from '~/composables/issues/useIssuesList/useIssuesListFilter'
import type { IssueSortTypes } from '~/composables/issues/useIssuesList/types'
import { stringifyQuery, transformFullPathToQueryObject } from '~/helpers/queryParser'
import { FILTERS_TO_BE_INCLUDED_IN_PATH } from '~/composables/filters/constants'
import { buildRequestSorting } from '~/composables/issues/useIssuesList/helpers'
import { parseUrlToFilters } from '~/composables/filters/helpers/parseUrlToFilters'
import type { IssueSortKeys } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import type { SortDirection } from '~/api/types'
import { buildURLFilterPath } from '~/composables/filters/useFiltersUrls'
import { validateUrlByFilters } from '~/composables/filters/helpers/validateUrlByFilters'

export type UseIssuesListUrlConfiguration = {
  search: Ref<string>
  filter: ComputedRef<Partial<IssuesListFilters>>
  appliedFilters: ComputedRef<Partial<IssuesListFilters>>
  sorting: ComputedRef<IssueSortTypes>
  fullPath: string
}

export function useIssuesConfigurationSync({ search, filter, sorting, appliedFilters, fullPath }: UseIssuesListUrlConfiguration) {
  function parseUrlToConfiguration(url: string) {
    const filtersFromPath = parseUrlToFilters(
      url.split('?')[0].split('#')[0],
    )

    const parsedFiltersQuery = transformFullPathToQueryObject(url, omit({
      ...useCloneDeep(filtersInitialState),
      search: String(search.value),
      sort: prepareSortQuery(sorting.value),
    }, FILTERS_TO_BE_INCLUDED_IN_PATH))

    return {
      search: cleanParsedSearch(parsedFiltersQuery.search ?? ''),
      filter: pick(
        { ...cloneDeep(filtersInitialState), ...parsedFiltersQuery, ...filtersFromPath },
        Object.keys(filtersInitialState),
      ),
      sorting: parsedFiltersQuery.sort ? parsedFiltersQuery.sort[0] as IssueSortTypes : sorting.value,
    }
  }

  function getCurrentQuery() {
    type QueryConfiguration = Partial<IssuesListFilters> & { sort: [IssueSortKeys, SortDirection] | null } & {
      search?: string
    }

    return transformFullPathToQueryObject(
      fullPath,
    ) as Record<string, unknown> & QueryConfiguration
  }

  function configurationToUrl() {
    const configurationKeys = [...Object.keys(filtersInitialState), 'sort']

    const parsedQuery = getCurrentQuery()
    const preparedSort = prepareSortQuery(sorting.value)
    const otherQuery = omit<Record<string, unknown>>(parsedQuery, configurationKeys)

    const queryObject = omit({
      ...otherQuery,
      ...{
        search: String(search.value) || null,
        ...appliedFilters.value,
        ...(preparedSort && { sort: preparedSort }),
      },
    }, FILTERS_TO_BE_INCLUDED_IN_PATH)

    const path = buildURLFilterPath(filter.value)
    return `${path}${stringifyQuery(queryObject)}${window.location.hash}`
  }

  function validateURL(url: string) {
    return validateUrlByFilters(url)
  }

  return {
    parseUrlToConfiguration,
    configurationToUrl,
    validateURL,
  }
}

function prepareSortQuery(sort: IssueSortTypes) {
  const builtSorting = buildRequestSorting(sort)
  return builtSorting ? [builtSorting.key, builtSorting.direction] : null
}

function cleanParsedSearch(search: string | object): string {
  return (typeof search === 'object' ? String(Object.values(search)[0]) : search) ?? ''
}
