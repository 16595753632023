import type { Ref } from 'vue'
import type { IssuesListFilters } from '~/stores/issues/types'
import { IssuesFiltersKeys } from '~/stores/issues/types'
import { isDefaultValue } from '~/composables/filters/helpers/isDefaultValue'
import { issuesFiltersRanges } from '~/stores/issues/constants'

export function useFilteredListsMeta(filters: Ref<Partial<IssuesListFilters>> | ComputedRef<Partial<IssuesListFilters>>, t: ReturnType<typeof useI18n>['t']) {
  const filteredListsMeta = useState('FILTERED_LISTS_META', () => ({
    listTitle: '',
    motto: '',
    seo: {
      title: '',
      description: '',
      keywords: '',
    },
  }))

  watchDebounced(filters, () => {
    const annualInterestRate = filters.value[IssuesFiltersKeys.AnnualInterestRate]
    const stateBonds = filters.value[IssuesFiltersKeys.StateBonds]
    const canBeBoughtOnline = filters.value[IssuesFiltersKeys.CanBeBoughtOnline]
    const collateralTypes = filters.value[IssuesFiltersKeys.CollateralTypes] || []

    const start = t(`bondList.filteredMeta.partials.stateBonds.${stateBonds ? 'on' : 'off'}`)

    let interestRate = ''

    const collaterals = collateralTypes?.length ? t('bondList.filteredMeta.partials.collaterals.on') : ''

    const canBeBoughtOnlineText = t(`bondList.filteredMeta.partials.canBeBoughtOnline.${canBeBoughtOnline ? 'on' : 'off'}`)
    const collateralTypesWithDeclensions = collateralTypes.map(collateral => t(`issuesListFilters.collaterals.declensions.instrumental.${collateral}`))

    filteredListsMeta.value.seo.keywords = [
      t('bondList.filteredMeta.seo.keywords.annualInterestRate'),
      ...collateralTypesWithDeclensions.map((collateral) => {
        return `${t('bondList.filteredMeta.seo.keywords.issues')} ${t('bondList.filteredMeta.seo.title.collaterals.on')} ${collateral}`
      }),
      ...(canBeBoughtOnline ? [t('bondList.filteredMeta.seo.keywords.canBeBoughtOnline')] : []),
      ...(stateBonds ? [t('bondList.filteredMeta.seo.keywords.stateBonds')] : []),
    ].join(', ')

    const [
      from = issuesFiltersRanges[IssuesFiltersKeys.AnnualInterestRate].min,
      to = issuesFiltersRanges[IssuesFiltersKeys.AnnualInterestRate].max,
    ] = annualInterestRate || []

    const [
      isFromDefault,
      isToDefault,
    ] = isDefaultValue(IssuesFiltersKeys.AnnualInterestRate, annualInterestRate || [issuesFiltersRanges.annualInterestRate.min, issuesFiltersRanges.annualInterestRate.max])

    if (!isFromDefault && !isToDefault) {
      interestRate = t('bondList.filteredMeta.partials.annualInterestRate.fromTo', { from, to })
    }
    else if (!isFromDefault) {
      interestRate = t('bondList.filteredMeta.partials.annualInterestRate.from', { from })
    }
    else {
      interestRate = t('bondList.filteredMeta.partials.annualInterestRate.to', { to })
    }

    filteredListsMeta.value.seo.title = clearString(`
      ${t(`bondList.filteredMeta.seo.title.stateBonds.${stateBonds ? 'on' : 'off'}`)}
      ${collaterals ? `${t('bondList.filteredMeta.seo.title.collaterals.on')} ${collateralTypesWithDeclensions.join(', ').toLocaleLowerCase()} ${t('bondList.filteredMeta.partials.divider')}` : t('bondList.filteredMeta.partials.annualInterestRate.divider')}
      ${interestRate}
      ${canBeBoughtOnlineText}
    `)

    filteredListsMeta.value.listTitle = clearString(`
      ${start}
      ${collaterals ? `${collaterals} ${collateralTypesWithDeclensions.join(', ').toLocaleLowerCase()} ${t('bondList.filteredMeta.partials.divider')}` : t('bondList.filteredMeta.partials.annualInterestRate.divider')}
      ${interestRate}
      ${canBeBoughtOnlineText}
  `)

    filteredListsMeta.value.seo.description = filteredListsMeta.value.listTitle

    filteredListsMeta.value.motto = t('bondList.filteredMeta.motto', {
      from: annualInterestRate?.[0] || issuesFiltersRanges[IssuesFiltersKeys.AnnualInterestRate].min,
      to: annualInterestRate?.[1] || issuesFiltersRanges[IssuesFiltersKeys.AnnualInterestRate].max,
    })
  }, { immediate: true, deep: true, debounce: 100 })

  return { filteredListsMeta }
}

function clearString(value: string) {
  return value.replace(/\n/g, '').replace(/\s+/g, ' ').trim()
}
