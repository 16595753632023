<script lang="ts" setup>
import { CfgHint, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

const head = [
  {
    key: '',
    // this index is according to the order in IssueTableBodyItemRow component
    // TODO: make this automatically according to IssueTableBodyItemRow
    index: 0,
  },
  {
    key: 'bondList.table.head.interestRate.title',
    hint: 'bondList.table.head.interestRate.hint',
    index: 1,
  },

  {
    key: 'issue.dmatChecks.title',
    hint: 'issuesListFilters.dmatChecks.hint',
    index: 2,
  },

  {
    key: 'bondList.table.head.maturityDate.title',
    hint: 'bondList.table.head.maturityDate.hint',
    index: 3,
  },
]
</script>

<template>
  <div class="flex items-center gap-x-5 px-4 text-grey-400">
    <IssueTableCell
      v-for="({ key, hint, abRisk, link, index }) in head"
      :key="key"
      v-slot="{ align }"
      :data-ab-risk="abRisk"
      :item-index="index"
    >
      <div
        class="flex shrink-0 items-center gap-x-1 lg:transition-colors lg:duration-200"
        :class="{
          'justify-center': align === 'center',
          'justify-end': align === 'end',
          'lg:hover:text-brand': !isEmpty(hint),
        }"
      >
        <ClientOnly v-if="hint">
          <CfgHint
            class="w-max shrink-0"
            :delay="[100, !!link ? 300 : 200]"
            hide-icon
            :interactive="!!link"
            :tooltip-padding="false"
          >
            <template #trigger>
              <CfgTypography class="underline decoration-dotted" :size="CfgTypographySizes.xs">
                {{ $t(key) }}
              </CfgTypography>
            </template>

            <template #content="{ initialized }">
              <div class="p-6">
                <span v-if="initialized" v-html="$t(hint, { link })" />
              </div>
            </template>
          </CfgHint>

          <template #fallback>
            <CfgTypography class="underline decoration-dotted" :size="CfgTypographySizes.xs">
              {{ $t(key) }}
            </CfgTypography>
          </template>
        </ClientOnly>

        <template v-else>
          <CfgTypography :size="CfgTypographySizes.xs">
            {{ $t(key) }}
          </CfgTypography>
        </template>
      </div>
    </IssueTableCell>
  </div>
</template>
