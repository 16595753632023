<script lang="ts" setup>
import { CfgTypography, type SelectOption } from '@cfgtech/ui'
import { CfgSelect } from '@cfgtech/ui'
import type { PropType } from 'vue'
import { IssueSortTypes } from '~/composables/issues/useIssuesList/types'

const sort = defineModel('sort', {
  type: String as PropType<IssueSortTypes>,
  required: true,
})

// composables
const responsive = useResponsive()

const { t } = useI18n()

// data
const items = computed(() =>
  [
    {
      label: t('bondList.lists.recommended.title'),
      type: IssueSortTypes.DEFAULT,
      isActive: sort.value === IssueSortTypes.DEFAULT,
    },
    {
      label: t('bondList.lists.dmatChecks.title'),
      type: IssueSortTypes.COLLATERALS,
      isActive: sort.value === IssueSortTypes.COLLATERALS,
    },
    {
      label: t('bondList.lists.mfcrScore.title'),
      abRisk: 'a',
      type: IssueSortTypes.MFCR_SCORE,
      isActive: sort.value === IssueSortTypes.MFCR_SCORE,
    },
    {
      label: t('bondList.lists.annualInterestRate.title'),
      type: IssueSortTypes.ANNUAL_INTEREST_RATE,
      isActive: sort.value === IssueSortTypes.ANNUAL_INTEREST_RATE,
    },
  ],
)

const selectItems = computed(() => items.value.map(({ label, type }) => ({
  value: type,
  label,
}) satisfies SelectOption<IssueSortTypes>))

const selectItemsSelected = computed({
  get() {
    const { type, label } = items.value.find(({ isActive }) => isActive)!
    return { value: type, label } satisfies SelectOption<IssueSortTypes>
  },

  set({ value }) {
    handleSortingSelect(value)
  },
})

function handleSortingSelect(type: IssueSortTypes) {
  sort.value = type
}
</script>

<template>
  <div>
    <CfgSelect
      v-if="responsive.mobile"
      v-model="selectItemsSelected"
      :options="selectItems"
      :placeholder="$t('bondList.sorting.title')"
    />

    <div v-else class="flex gap-x-[26px]">
      {{ $t('bondList.sorting.title') }}

      <CfgTypography
        v-for="{ label, type, isActive, abRisk } in items"
        :key="type"
        class="cursor-pointer"
        :class="{
          'font-highlighted': isActive,
          'text-brand underline hover:no-underline': !isActive,
        }"
        :data-ab-risk="abRisk"
        @click.prevent="handleSortingSelect(type)"
      >
        {{ label }}
      </CfgTypography>
    </div>
  </div>
</template>
