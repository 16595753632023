<script setup lang="ts">
import { QuestionCircleIcon } from '@cfgtech/icons'
import { CfgCheckbox, CfgHint } from '@cfgtech/ui'

defineProps<{
  id?: string
  label: string
  hint?: string
  link?: string
}>()

const modelValue = defineModel<boolean>({ default: false })
</script>

<template>
  <div class="flex gap-x-2">
    <CfgCheckbox
      :id="id"
      v-model="modelValue"
      class="shrink-0"
      :link="link"
      :placeholder="label"
    />

    <CfgHint
      v-if="hint"
      :disabled="!hint"
      hide-icon
    >
      <template #trigger>
        <QuestionCircleIcon class="text-[20px] text-grey-300" />
      </template>

      <template #content="{ initialized }">
        <ClientOnly>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-if="initialized" v-html="hint" />
        </ClientOnly>
      </template>
    </CfgHint>
  </div>
</template>
