import type { Issue } from '~/api/types'
import type { ShortIssuesPopulateKeys } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'

export enum SchemaOrgIssueKeys {
  name = 'name',
  nominalValue = 'nominalValue',
  currency = 'currency',
  slug = 'slug',
  jamesOrderFormURL = 'jamesOrderFormURL',
}

export type SchemaOrgIssue = Pick<Issue, SchemaOrgIssueKeys | ShortIssuesPopulateKeys> & {
  id: number
}
