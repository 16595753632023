<script setup lang="ts">
import { CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

const props = defineProps<{
  posts: BlogPost[]
}>()

defineEmits<{
  showMoreArticles: []
}>()

const {
  onSrovComment,
} = useDataLayer()

const route = useRoute()

const posts = toRef(props.posts)

function handleCommentsDataLayerUpdate(id: number) {
  onSrovComment(route.fullPath, posts.value.find(post => post.id === id))
}
</script>

<template>
  <div>
    <CfgTypography
      class="mb-8 text-center !font-highlighted lg:mb-10"
      :size="CfgTypographySizes.h2"
      tag-name="h2"
    >
      {{ $t('common.newsletter.small.title') }}
    </CfgTypography>

    <BlogPreviewCards
      enable-card-shadow
      :posts="posts"
      @data-layer-update="handleCommentsDataLayerUpdate"
    />

    <div class="mt-8 flex w-full items-center justify-center lg:mt-10">
      <NuxtLink to="/blog#posts" @click="$emit('showMoreArticles')">
        <CfgButton
          as="span"
          class="px-5"
          :label="$t('homepage.showMoreArticles.title')"
          variant="primary"
        />
      </NuxtLink>
    </div>
  </div>
</template>
