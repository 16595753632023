import type { Strapi4RequestParams, Strapi4Response } from '@nuxtjs/strapi'
import { apiRequest } from '~/api/helpers/apiRequest'
import { ShortIssuesPopulateKeys } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { simplifyStrapiCollectionResponse } from '~/api/helpers/functions'
import type { ApiResponseData, ApiResponseMeta } from '~/api/types'
import type { SchemaOrgIssue } from '~/api/modules/issues/services/getSchemaOrgIssues/getSchemaOrgIssues.types'
import { SchemaOrgIssueKeys } from '~/api/modules/issues/services/getSchemaOrgIssues/getSchemaOrgIssues.types'

export async function getSchemaOrgIssuesService(): Promise<ApiResponseData<SchemaOrgIssue[]>> {
  const params: Strapi4RequestParams = {
    fields: Object.values(SchemaOrgIssueKeys),

    populate: Object.values(ShortIssuesPopulateKeys),

    pagination: { page: 1, pageSize: 10 },

    filters: {
      $or: [
        { jamesOrderFormURL: { $notNull: true } },
        { jamesOrderFormURL: { $ne: '' } },
      ],
    },
  }

  const response = await apiRequest<Strapi4Response<SchemaOrgIssue>>(
    '/issues',
    params,
  )

  return response
    ? {
        data: simplifyStrapiCollectionResponse<SchemaOrgIssue>(response),
        meta: response.meta as ApiResponseMeta,
      }
    : { data: [] }
}
